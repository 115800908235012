.card-event {
  display: flex;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
}

.card {
  &.large {
    &.card-shadow {
      border-radius: 16px;
    }
  }
}

.upcoming-event {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    position: relative;

    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }

    &__title {
      position: relative;
      z-index: 5;

      &.card-container {
        padding: 30px 7% 15px 7%;
      }

      span {
        color: white;
        font-family: Comfortaa;
        font-size: 33px;
        line-height: 44px;
        font-weight: bold;

        @media (max-width: 560px) {
          font-size: 22px;
        }
      }
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.card-container {
      padding: 20px;
    }

    &__data {
      width: 50%;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 12px;
    }

    &__cta {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: flex-start;
    }
  }
}
