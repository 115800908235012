@import '../../../styles/variables.scss';

.box-categories {
  margin-bottom: 30px;
}
.img-down {
  margin-top: -5px;
  display: inline;
  width: 30px;
}

.text-button {
  display: flex;
  align-items: center;
}

.text-categoria {
  display: inline;
  font-size: 15px;
  font-family: 'Comfortaa';
  font-weight: 700;
  line-height: 10px;
  margin-left: 7.93px;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-out;
}

.fade-leave {
  opacity: 1;
}

.fade-leave-active {
  opacity: 0;
  transition: opacity 400ms ease-out;
}
