@import '../../styles/variables.scss';

.footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 140px;
  padding-bottom: 40px;

  a {
    text-decoration: none;
  }

  p {
    padding-top: 36px;
    text-transform: capitalize;
    width: 224px;
  }

  p,
  a {
    color: $violetTitle;
    font-family: 'PT Mono';
    font-size: 13px;
    line-height: 26px;
    text-align: center;
    margin: 0;
  }
}
