@import '../../../styles/variables.scss';

.event-details {
  width: 100%;
  height: 100%;
  max-height: 150px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.event-detail {
  display: flex;
  align-items: flex-start;

  &.past {
    span {
      color: $pastGrey;
    }
  }

  &:not(.past) {
    margin-bottom: 12px;
  }

  &.event-date {
    span {
      text-transform: capitalize;
    }
  }

  span {
    margin-left: 15px;
    font-family: Comfortaa;
    font-size: 14px;
    font-weight: bold;
    line-height: 23px;
  }
}
