@import '../../../styles/variables.scss';

.stats {
  &--background {
    background: url('../../../assets/stats.png') no-repeat;
    background-size: cover;
    background-position: 70% 0%;
    height: 100%;
    padding: 20px 0;
  }

  &__title {
    display: flex;
    justify-content: center;
    padding: 60px 0;

    @media (max-width: 720px) {
      padding: 60px 20px;
    }

    .title {
      display: flex;
      text-align: center;
      color: white;
      font-family: 'Comfortaa';
      font-size: 30px;
      line-height: 43px;
      margin: 0;

      @media (max-width: 480px) {
        font-size: 24px;
      }
    }
  }

  &__container {
    display: flex;
    justify-content: center;

    @media (max-width: 720px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      align-items: center;
      justify-items: center;
    }

    &__stat {
      height: 140px;
      width: 140px;
      border-radius: 70px;
      background: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 0 20px;

      @media (max-width: 720px) {
        margin-top: 30px;
      }

      span {
        color: $violetCard;
        font-family: 'Comfortaa';
        width: 80%;
        text-align: center;

        &:first-child {
          height: 50%;
          font-size: 30px;
          display: flex;
          justify-content: center;
          align-items: flex-end;
          font-size: 30px;
          font-weight: bold;
        }

        &:last-child {
          line-height: 16px;
          font-size: 14px;
          margin-top: 6px;
        }
      }
    }
  }

  &__btn {
    display: flex;
    justify-content: center;
    margin: 60px 0;
  }
}
