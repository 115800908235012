@import '../../styles/variables.scss';

.events {
  background: white;

  &__subtitle {
    padding: 0 5%;

    h4 {
      color: $violetTitle;
      text-transform: uppercase;
      font-family: Comfortaa;
      font-size: 15px;
      line-height: 28px;
      font-weight: bold;
      letter-spacing: 2px;
      margin-bottom: 30px;

      @media (max-width: 480px) {
        margin-top: 50px;
      }
    }
  }

  &__container {
    padding-left: calc(5% - 16px);
    padding-right: calc(5% - 16px);
  }

  &__upcoming-events {
    margin-top: -200px;
    position: relative;
    z-index: 10;
    background: transparent;
    margin-bottom: 80px;
  }

  &__past-events {
    margin-bottom: 110px;

    &__btn {
      display: flex;
      justify-content: center;
      height: 93px;

      button {
        margin-top: 40px;
      }
    }
  }
}
