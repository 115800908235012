@import '../../styles/variables.scss';

$break-small: 320px;
$break-medium: 0px;
$break-large: 1200px;

.menu {
  position: absolute;
  left: 0;
  top: 0;

  > div {
    > div:last-child {
      width: 95vw;
      height: 140px;
      position: relative;
      display: flex;
      justify-content: flex-end;
    }
  }

  &.lightTheme {
    .bm-burger-bars {
      background: $violetCard;
    }
  }
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: absolute;
  width: 36px;
  height: 22px;
  right: 26px;
  top: 59px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
  height: 15% !important;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  // background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  top: 49px !important;
  right: 69px !important;
  height: 50px !important;
  width: 50px !important;
}

/* Color/shape of close button cross */
.bm-cross {
  background: white;
  height: 30px !important;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: $violetCard;
  padding: 175px 69px 0;
  background: $violetCard;
  padding: 175px 60px 0;
}

/* Wrapper for item list */
.bm-item-list {
  color: white;
}

/* Individual item */
.bm-item {
  display: inline-block;
  color: white;
  text-decoration: none;
  line-height: 69px;
  font-weight: bold;
  font-size: 23px;
  letter-spacing: 2px;
  text-align: right;
  font-family: Comfortaa;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.main-header {
  height: 140px;
  background: transparent;

  .container {
    display: flex;
    justify-content: space-between;
    height: 100;
  }

  .header-logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .logo {
      top: 48px;
      height: 40px;
      margin-left: 30px;

      @media (min-width: 425px) {
        margin-left: 69px;
        height: 56px;
      }

      position: relative;
      width: auto;
      z-index: 10;
    }
  }
}
