@import '../../../styles/variables.scss';

.box-community {
  margin-top: 46px;
  width: 260px;
  padding: 30px;
}
.card-community img {
  height: 80px;
}
.community-text {
  .title {
    margin-top: 26px;
    margin-bottom: 0;
    font-size: 25px;
    font-family: 'Comfortaa';
    color: rgba(30, 19, 70, 0.73);
    font-weight: 500;
    line-height: 36px;
  }
  .text {
    margin-top: 16px;
    font-family: 'Comfortaa';
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.63px;
    line-height: 24px;
    color: $textCard;
  }
}
