@import '../../../styles/variables.scss';

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 110px;
  flex-direction: column;

  .container-social {
    display: flex;
    justify-content: center;
    width: 90%;
    flex-wrap: wrap;
    margin-top: 40px;

    .box-social {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 30px;
    }
  }

  .img {
    &.twitter {
      img {
        width: 40px;
      }
    }
    &.spotify {
      img {
        max-width: 160px;
      }
    }
    img {
      max-height: 60px;
      max-width: 120px;
    }
  }
}

.blog-iframe {
  border:1px solid #EEE; 
  background:white;

  button {
    .rightButton {
      background: $violetCard !important; 
    }
  }
}

.sub-title p {
  margin: 0;
  color: rgba(30, 19, 70, 0.73);
  font-family: 'Comfortaa';
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 2.4px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
}
