@font-face {
  font-family: 'Comfortaa';
  src: url('../assets/fonts/Comfortaa[wght].ttf') format('woff');
}

@font-face {
  font-family: 'PT Mono';
  src: url('../assets/fonts/PTM55FT.ttf') format('woff');
}

body,
html {
  margin: 0;
  padding: 0;
  background-color: #fafbff;
  box-sizing: border-box;
  font-family: Confortaa;

  span {
    margin: 0;
  }
}

.center-item {
  display: flex;
  align-items: center;
}
