@import '../../../styles/variables.scss';

.event-header {
  &__icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon-wrapper {
    width: 31px;
    height: 24px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__title-container {
    display: flex;
    justify-content: flex-start;

    h3 {
      padding-left: 30px;
      font-family: Comfortaa;
      color: $violetTitle;
      font-weight: bold;
      font-size: 15px;
      letter-spacing: 2.4px;
    }
  }
}
