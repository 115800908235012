@import '../../../styles/variables.scss';

.banner {
  margin-top: -140px;
  position: relative;
  overflow: hidden;
  height: 600px;
  display: flex;


  @media (max-width: 760px) {
    height: 800px;
  }

  @media (max-width: 480px) {
    height: 800px;

  }

  &.top {
    img {
      object-position: center top;
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center bottom;
  }

  .banner__text {
    position: absolute;
    width: 100%;
    top: 30%;
    text-align: center;

    &.lightTheme {
      top: 28%;
      h4 {
        color: $violetCard;
      }
    }

    h4 {
      margin: 0;
      font-size: 30px;
      line-height: 48px;
      white-space: pre-wrap;
      color: white;
      font-weight: bold;
      font-family: Comfortaa;

      @media (max-width: 760px) {
        padding: 0 20px;
      }

      @media (max-width: 480px) {
        font-size: 22px;
        margin: 40px 0;

      }
    }
  }
}
