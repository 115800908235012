@import '../../../styles/variables.scss';

.categories {
  padding: 0px 15%;
  overflow-y: auto;
  max-height: 75vh;
  @media (max-width: 1366px) {
    padding: 0px 10%;
  }
  @media (max-width: 960px) {
    padding: 0px 5%;
  }

  &__emptyState {
    font-size: 22px;
    font-family: 'Comfortaa';
    font-weight: 700;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 300px;
    color: $categoryText;
  }
}

// @media (max-width: #{$desktop-1200px}) {
//   .categories {
//     padding: 0px 90px 0px 0 90px;
//   }
// }

// @media (max-width: #{$tablet-800px}) {
//   .categories {
//     padding: 0px 50px 0px 50px;
//   }
// }
