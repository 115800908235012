@import '../../../styles/variables.scss';

.partners {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 110px;

  @media (max-width: 900px) {
    margin-top: 80px;
  }
  .container-partners {
    display: flex;
    height: auto;
    justify-content: space-between;
    align-items: center;
    padding: 0 80px;
    flex-wrap: wrap;
    margin-top: 40px;
    @media (max-width: 1200px) {
      width: 90%;
      padding: 0;
    }
    .partners-logo {
      flex: 1;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      // min-width: 150px;
      height: 60px;
      margin: 20px 0;
      .partner-link{
        margin: 0 20px;
        img {
          max-height: 100%;
          width: auto;
        }
      }
    }
  }
}
