@import '../../../styles/variables.scss';

.community {
  box-sizing: border-box;

  
  &__header {
    margin-bottom: 110px;

    @media (max-width: 1280px) {
      margin-top: 100px;
    }

    @media (max-width: 960px) {
      margin-top: 500px;
    }
  }

  .container-community {
    padding: 110px 80px 0px 80px;

    @media (max-width: 768px) {
      padding: 110px 20px 0px 20px;
    }
  }
}

.title-2 {
  text-align: center;
  margin-top: 26px;
  margin-bottom: 20px;
  font-size: 30px;
  font-family: 'Comfortaa';
  color: $violetCard;
  font-weight: 500;
  line-height: 43px;
  max-width: 874px;

  @media (max-width: 1200px) {
    max-width: unset;
    margin-left: 25px;
    margin-right: 25px;
    font-size: 25px;
  }
  @media (max-width: 900px) {
    max-width: unset;
    margin-left: 17px;
    margin-right: 17px;
    font-size: 20px;
  }
}

.sub-title {
  display: flex;
  justify-content: center;
}

.sub-title h1 {
  margin: 0;
  color: rgba(30, 19, 70, 0.73);
  font-family: 'Comfortaa';
  font-size: 15px;
  font-weight: 700;
  letter-spacing: 2.4px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
}

// @media (max-width: #{$desktop-959px}) {
//   .title-2 {
//     font-size: 25px;
//     padding: 2%;
//   }

//   .community {
//     .container-community {
//       padding: 10px 0% 0% 20px;
//     }
//   }
// }

// @media (min-width: #{$phone-480px}) and (max-width: #{$tablet-700px}) {
//   .title-2 {
//     font-size: 25px;
//     padding: 2%;
//   }

//   .community {
//     .container-community {
//       padding: 10px 0% 0% 20px;
//     }
//   }
// }

// @media (min-width: #{$phone-300px}) and (max-width: #{$phone-480px}) {
//   .title-2 {
//     font-size: 20px;
//     padding: 2%;
//   }
//   .community {
//     .container-community {
//       padding: 10px 0% 0% 20px;
//     }
//   }
// }
