@import '../../../styles/variables.scss';

.text-categoria {
  color: $categoryText;
  display: inline;
  font-size: 15px;
  font-family: 'Comfortaa';
  font-weight: 700;
  line-height: 10px;
  margin-left: 7.93px;
  letter-spacing: 1.36px;
  line-height: 10px;
  text-transform: uppercase;
}

.text-linkCard {
  color: $orange;
  padding-left: 16px;
  width: 40%;
  @media (max-width: 960px) {
    padding-right: 16px;
  }
}

.text-linkCard-2 {
  color: rgba(30, 19, 70, 0.26);
  padding-right: 16px;
  width: 35%;
  @media (max-width: 960px) {
    padding-right: 16px;
    padding-left: 16px;
    margin-top: 0;
  }
}

.container-linkCard {
  margin: 10px 0;
  width: 100%;
  height: 54px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  text-decoration: none;
  @media (max-width: 960px) {
    flex-direction: column;
    height: 100px;
  }
  p {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Comfortaa';
    font-size: 15px;
    font-weight: 700;
    letter-spacing: 1px;
    line-height: 27px;
    display: block;
    box-sizing: border-box;
    @media (max-width: 960px) {
      width: 100%;
    }
  }
}

.card-shadow {
  box-shadow: 2px 11px 20px 1px rgba(31, 41, 108, 0.07);
  background-color: white;
  border-radius: 8px;
}

.card-shadow:hover {
  box-shadow: 2px 11px 20px 1px rgba(31, 41, 108, 0.1);
}
