$orange: #ff6d66;
$orangeAlpha: #ff9994;
$violetCard: #5555fe;
$violetTitle: #1e1346;
$violetSubtitle: #1e1346ba;
$textCard: #8883a1;
$background: #fafbff;
$categoryText: #595177;
$pastGrey: #c5c2cf;

$phone-300px: 300px;
$phone-480px: 480px;
$tablet-600px: 600px;
$tablet-700px: 700px;
$tablet-800px: 800px;
$desktop-959px: 959px;
$desktop-1200px: 1200px;
$desktop-1440px: 1440px;
$desktop-1920px: 1920px;
